import {InferActions} from 'app/types';
import {
    IChangesLogItem,
    IFilterSettings,
    IPaginationSettings,
    IPaginationSettingsExtended,
    ISorterSettings,
} from 'entities/ChangesLog/types';

import * as types from '../actionTypes';

import {actionCreators} from '../actions';

export interface IChangesLogState {
    data: IChangesLogItem[];
    isLoading: boolean;
    isError: boolean;
    pagination_settings: IPaginationSettings & Partial<IPaginationSettingsExtended>;
    filter_settings: Partial<IFilterSettings>;
    sorter_settings: ISorterSettings;
}

export const defaultState: IChangesLogState = {
    data: [],
    isLoading: false,
    isError: false,
    pagination_settings: {page: 1, perPage: 50},
    filter_settings: {},
    sorter_settings: {order_by: 'desc'},
};

export type ActionsTypes = InferActions<typeof actionCreators>;

export default function (state: IChangesLogState = defaultState, action: ActionsTypes): IChangesLogState {
    switch (action.type) {
        case types.FETCHING_CHANGES_LOG_LIST_RECEIVED: {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                isError: false,
            };
        }

        case types.SET_CHANGES_LOG_LIST_PAGINATION_SETTINGS: {
            return {
                ...state,
                pagination_settings: {...state.pagination_settings, ...action.payload},
            };
        }

        case types.SET_CHANGES_LOG_LIST_FILTER_SETTINGS: {
            return {
                ...state,
                filter_settings: action.payload,
            };
        }

        case types.FETCHING_CHANGES_LOG_LIST_REQUESTED: {
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        }

        case types.FETCHING_CHANGES_LOG_LIST_FAILED: {
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        }

        case types.CLEAR_CHANGES_LOG_LIST_FILTERS: {
            return {
                ...state,
                filter_settings: {},
            };
        }

        case types.CLEAR_CHANGES_LOG_LIST_PAGINATION: {
            return {
                ...state,
                pagination_settings: defaultState.pagination_settings,
            };
        }

        case types.CLEAR_CHANGES_LOG_LIST_SORTING: {
            return {
                ...state,
                sorter_settings: defaultState.sorter_settings,
            };
        }

        case types.SET_CHANGES_LOG_LIST_SORTING: {
            return {
                ...state,
                sorter_settings: action.payload,
            };
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
