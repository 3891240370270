import React, {Suspense} from 'react';
import {shortLogoPath, longLogoPath} from 'clusterSettings/routing';

interface OwnProps {
    short?: boolean;
}

const Logo: React.FC<OwnProps> = ({short}) => {
    const path = short ? shortLogoPath : longLogoPath;

    const LogoComponent = React.lazy(() => import(`components/Logo/${path}`));
    return (
        <Suspense fallback={<div />}>
            <LogoComponent />
        </Suspense>
    );
};

export default Logo;
