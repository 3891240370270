import React, {useState} from 'react';
import {Radio} from 'antd';

import {REGIONS} from 'utils/data/regions';

import styles from './styles.module.scss';

type OwnProps = {
    value: string;
    onSelect(value: string): void;
};

const RegionSelect: React.FC<OwnProps> = (props) => {
    const {value, onSelect} = props;
    const [showAllEuropeCountries, setShowAllEuropeCountries] = useState(false);
    return (
        <div>
            <div>
                <div>
                    <Radio.Group
                        options={[{label: REGIONS.NA.title, value: REGIONS.NA.value}]}
                        onChange={(event) => onSelect(event.target.value as string)}
                        value={value}
                    />
                </div>
                <div className={styles.text_container}>
                    <span>USA, Canada, Mexico</span>
                </div>
            </div>

            <br />

            <div>
                <div>
                    <Radio.Group
                        options={[{label: REGIONS.EU.title, value: REGIONS.EU.value}]}
                        onChange={(event) => onSelect(event.target.value as string)}
                        value={value}
                    />
                </div>
                <div className={styles.text_container}>
                    {showAllEuropeCountries ? (
                        <>
                            <span>Akrotiri, Albania, Armenia, Andorra, Austria, Azerbaijan, Belarus, Belgium,</span>
                            <span>Bosnia and Herzegovina, Bulgaria, Croatia, Cyprus, Czech Republic,</span>
                            <span>Denmark, Dhekelia, Estonia, Faroe Islands, Finland, France, Georgia,</span>
                            <span>Germany, Gibraltar, Greece, Guernsey, Hungary, Iceland, Ireland, Isle of Man,</span>
                            <span>Italy, Jersey, Kazakhstan, Kosovo, Kyrgyzstan, Latvia, Liechtenstein,</span>
                            <span>Lithuania, Luxembourg, Macedonia, Malta, Moldova, Monaco,</span>
                            <span>Montenegro, Netherlands, Norway, Poland, Portugal, Romania, Russia,</span>
                            <span>San Marino, Serbia, Slovakia, Slovenia, Spain, Svalbard and Jan Mayen Islands,</span>
                            <span>
                                Sweden,Switzerland, Tajikistan, Turkey, Turkmenistan, Ukraine, United Kingdom,
                                Uzbekistan, Vatican City
                            </span>
                        </>
                    ) : (
                        <span>
                            Akrotiri, Albania, Armenia, Andorra, Austria, Azerbaijan...{' '}
                            <span className="customLink" onClick={() => setShowAllEuropeCountries(true)}>
                                Show all
                            </span>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RegionSelect;
