import {Company} from 'entities/Companies/types';

import {InferActions, OptionSelect} from 'app/types';

import {actionCreators} from '../actions/list';
import * as types from '../actionTypes/list';

export interface CompaniesState {
    selectOptions: OptionSelect[];
    companies: Company[];
    isError: boolean;
}

export const defaultState: CompaniesState = {
    selectOptions: [],
    companies: [],
    isError: false,
};

export type ActionsTypes = InferActions<typeof actionCreators>;

export default function usersReducer(state = defaultState, action: ActionsTypes): CompaniesState {
    switch (action.type) {
        case types.FETCH_LIST_COMPANIES_RECEIVED: {
            return {
                ...state,
                companies: action.payload,
            };
        }

        case types.FETCH_LIST_COMPANIES_FAILURE: {
            return {
                ...state,
                isError: true,
            };
        }

        case types.SET_GLOBAL_POOL_PARAMETER: {
            const {id, isGlobal} = action.payload;

            const newCompanies = state.companies.map((company) =>
                company.id === id ? {...company, in_global_pool: isGlobal} : company,
            );

            return {
                ...state,
                companies: newCompanies,
            };
        }

        case types.COMPANIES_SELECT_OPTIONS_RECEIVED: {
            return {
                ...state,
                selectOptions: action.payload.options,
            };
        }

        // no default
    }

    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
