import React from 'react';
import Menu from 'antd/lib/menu';
import Layout from 'antd/lib/layout';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {NavLink, useLocation} from 'react-router-dom';
import {RocketOutlined, UserOutlined, HistoryOutlined, ShakeOutlined, TrophyOutlined} from '@ant-design/icons';

import Logo from 'components/Logo';

import useModuleStyles from 'app/hooks/useModuleStyles';

const LeftSidebar = (): JSX.Element | null => {
    const {pathname} = useLocation();
    const styles = useModuleStyles('layout/LeftSidebar/styles');

    if (!styles) {
        return null;
    }

    const [pathName] = pathname.split('/').filter((i) => i);

    const items: ItemType[] = [
        {label: <NavLink to="/companies">Companies</NavLink>, key: '/companies', icon: <RocketOutlined />},
        {label: <NavLink to="/mobile">Mobile App</NavLink>, key: '/mobile', icon: <ShakeOutlined />},
        {
            label: <NavLink to="/network-benefits">Network benefits</NavLink>,
            key: '/network-benefits',
            icon: <TrophyOutlined />,
        },
        {label: <NavLink to="/users">Users</NavLink>, key: '/users', icon: <UserOutlined />},
        {label: <NavLink to="/changes-log">Changes Log</NavLink>, key: '/changes-log', icon: <HistoryOutlined />},
    ];

    return (
        <Layout.Sider className={styles.wrap} width={260}>
            <div className={styles.logo}>
                <Logo short />
            </div>
            <Menu
                selectedKeys={['/'.concat(pathName || 'companies')]}
                defaultSelectedKeys={['/']}
                items={items}
                theme="dark"
                mode="inline"
            />
        </Layout.Sider>
    );
};

export default LeftSidebar;
