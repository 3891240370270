import React, {useState} from 'react';
import {Modal} from 'antd';

import {Company} from 'entities/Companies/types';

import RegionSelect from '../RegionSelect';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        company: Company;
    };
    handlers: {
        onChangeRegion(region: string): void;
    };
    onClose(): void;
}

const ChangeRegionModal = (props: OwnProps): JSX.Element => {
    const {
        data: {company},
        handlers,
        onClose,
    } = props;
    const [region, setRegion] = useState(company.region as string);
    return (
        <>
            <Modal
                title="Change Company Continent"
                visible={true}
                okButtonProps={{disabled: region === company.region}}
                onOk={() => handlers.onChangeRegion(region)}
                onCancel={onClose}
            >
                <p>
                    <strong>Continent where the company operates</strong>
                </p>
                <div className={styles.textContainer}>
                    After changing the continent, the provider key needs to be changed.
                </div>
                <div className={styles.textContainer}>Please enter the key after saving.</div>
                <br />
                <RegionSelect value={region} onSelect={setRegion} />
            </Modal>
        </>
    );
};

export default ChangeRegionModal;
