import {InferActions} from 'app/types';

import {Company} from 'entities/Companies/types';

import * as actionsTypes from 'pages/Companies/redux/actionTypes/company';
import {actionCreators} from 'pages/Companies/redux/actions/company';
import * as constants from 'pages/Companies/constants';

export type ActiveCurrentCard =
    | typeof constants.COMPANY_GENERAL_CARD
    | typeof constants.COMPANY_EMAILS_CARD
    | typeof constants.COMPANY_MAP_CARD
    | typeof constants.COMPANY_ADDITIONAL_CARD
    | typeof constants.COMPANY_GLOBAL_CARD
    | typeof constants.COMPANY_VISUAL_CARD
    | typeof constants.COMPANY_NETWORK_BENEFITS_CARD
    | null;

export type CompanyState = {
    data: Partial<Company>;
    isLoading: boolean;
    isError: boolean;
    activeCurrentCard: ActiveCurrentCard;
    updatingMapSettings: boolean;
    updatingDefaultSettings: boolean;
    updatingVisualSettings: boolean;
    updatingLoadStartNumber: boolean;
    updatingEmailSendersSettings: boolean;
    updatingGeneralInfo: boolean;
    updatingNetworkBenefits: boolean;
    succeed: boolean;
};

export const defaultState: CompanyState = {
    data: {},
    isLoading: false,
    isError: false,
    activeCurrentCard: null,
    updatingMapSettings: false,
    updatingDefaultSettings: false,
    updatingVisualSettings: false,
    updatingLoadStartNumber: false,
    updatingEmailSendersSettings: false,
    updatingGeneralInfo: false,
    updatingNetworkBenefits: false,
    succeed: false,
};

export type ActionsTypes = InferActions<typeof actionCreators>;

export default function companyReducer(state: CompanyState = defaultState, action: ActionsTypes): CompanyState {
    switch (action.type) {
        case actionsTypes.FETCH_CURRENT_COMPANY_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionsTypes.FETCH_CURRENT_COMPANY_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                isLoading: false,
                activeCurrentCard: null,
                updatingMapSettings: false,
                updatingDefaultSettings: false,
                updatingLoadStartNumber: false,
                updatingEmailSendersSettings: false,
                updatingGeneralInfo: false,
            };
        }

        case actionsTypes.FETCH_CURRENT_COMPANY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                updatingMapSettings: false,
                updatingDefaultSettings: false,
                updatingVisualSettings: false,
                updatingLoadStartNumber: false,
                updatingEmailSendersSettings: false,
                updatingGeneralInfo: false,
                succeed: false,
            };
        }

        case actionsTypes.SET_ACTIVE_CURRENT_CARD: {
            return {
                ...state,
                activeCurrentCard: action.payload,
                isError: false,
                succeed: false,
            };
        }

        case actionsTypes.UPDATE_MAP_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    maps_settings: action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingMapSettings: false,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_GLOBAL_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    default_formats: action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingDefaultSettings: false,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_VISUAL_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingVisualSettings: false,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_LOAD_START_NUMBER_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    load_start_number_settings: action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingLoadStartNumber: false,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_EMAIL_SENDERS_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    emails_settings: action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingEmailSendersSettings: false,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_GENERAL_INFO_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
                isLoading: false,
                isError: false,
                updatingGeneralInfo: false,
                activeCurrentCard: null,
                succeed: true,
            };
        }

        case actionsTypes.UPDATE_REGION_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                updatingGeneralInfo: false,
                updatingDefaultSettings: false,
            };
        }

        case actionsTypes.UPDATE_COMPANY_NETWORKS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
                updatingNetworkBenefits: false,
                activeCurrentCard: null,
                succeed: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_MAP_SETTINGS_REQUEST: {
            return {
                ...state,
                updatingMapSettings: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_GLOBAL_SETTINGS_REQUEST: {
            return {
                ...state,
                updatingDefaultSettings: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_VISUAL_SETTINGS_REQUEST: {
            return {
                ...state,
                updatingVisualSettings: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_LOAD_START_NUMBER_REQUEST: {
            return {
                ...state,
                updatingLoadStartNumber: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_EMAIL_SENDERS_SETTINGS_REQUEST: {
            return {
                ...state,
                updatingEmailSendersSettings: true,
            };
        }

        case actionsTypes.UPDATE_COMPANY_NETWORKS_REQUEST: {
            return {
                ...state,
                updatingNetworkBenefits: true,
            };
        }

        case actionsTypes.SET_COMPANY_REGION: {
            return {
                ...state,
                data: {...state.data, region: action.payload},
            };
        }

        case actionsTypes.FETCH_CREATE_COMPANY_REQUEST: {
            return {
                ...state,
                isLoading: true,
                isError: false,
            };
        }

        case actionsTypes.FETCH_CREATE_COMPANY_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isError: false,
            };
        }

        case actionsTypes.FETCH_CREATE_COMPANY_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        }

        case actionsTypes.SET_COMPANY_ACTIVATION_STATUS: {
            return {
                ...state,
                data: {...state.data, activated: action.payload},
            };
        }

        case actionsTypes.FETCH_UPDATE_GENERAL_INFO_REQUEST: {
            return {
                ...state,
                updatingGeneralInfo: true,
            };
        }

        case actionsTypes.CLEAR_CURRENT_COMPANY_STATE: {
            return defaultState;
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
