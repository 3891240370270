import {Action} from 'redux';

import * as types from 'app/actions/actionTypes';

import globalRegister from 'utils/globalRegister';

type ShowModalParams = {
    name: string;
    mode?: string;
    data: any;
    handlers: Record<string, (param: any) => void | null | undefined>;
    onSubmit?: () => void;
    closeBeforeSubmit?: () => void;
};

type ShowModalAction = {
    type: typeof types.SHOW_MODAL;
    payload: {
        name: string;
        mode?: string;
        data: any;
        closeBeforeSubmit?: () => void;
    };
};

export function showLoaderSpinner() {
    return {
        type: types.SHOW_LOADER_SPINNER,
    };
}

export function hideLoaderSpinner() {
    return {
        type: types.HIDE_LOADER_SPINNER,
    };
}

export function showModal(params: ShowModalParams): ShowModalAction {
    const {name, mode, onSubmit, handlers = {}, closeBeforeSubmit, data} = params;
    const allHandlersMap = {onSubmit, ...handlers};

    globalRegister.setHandlers(allHandlersMap);

    return {
        type: types.SHOW_MODAL,
        payload: {
            name,
            mode,
            data,
            closeBeforeSubmit,
        },
    };
}

export function hideModal(): Action {
    globalRegister.removeHandlers();
    return {
        type: types.HIDE_MODAL,
    };
}
