import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';

import {AppState} from 'app/types';
import {isUserLoggedIn} from 'app/reducers/userData/selectors';

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: (state: AppState) => !isUserLoggedIn(state),
    wrapperDisplayName: 'UserIsNotAuthenticated',
    redirectPath: () => '/companies',
    allowRedirectBack: false,
});

export const userIsAuthenticatedRedir = connectedRouterRedirect({
    authenticatedSelector: (state: AppState) => isUserLoggedIn(state),
    wrapperDisplayName: 'UserIsAuthenticated',
    redirectPath: '/login',
});
