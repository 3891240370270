export const FETCHING_CHANGES_LOG_LIST_REQUESTED = 'FETCHING_CHANGES_LOG_LIST_REQUESTED';
export const FETCHING_CHANGES_LOG_LIST_RECEIVED = 'FETCHING_CHANGES_LOG_LIST_RECEIVED';
export const FETCHING_CHANGES_LOG_LIST_FAILED = 'FETCHING_CHANGES_LOG_LIST_FAILED';

export const SET_CHANGES_LOG_LIST_PAGINATION_SETTINGS = 'SET_CHANGES_LOG_LIST_PAGINATION_SETTINGS';
export const CLEAR_CHANGES_LOG_LIST_PAGINATION = 'CLEAR_CHANGES_LOG_LIST_PAGINATION';

export const SET_CHANGES_LOG_LIST_FILTER_SETTINGS = 'SET_CHANGES_LOG_LIST_FILTER_SETTINGS';
export const CLEAR_CHANGES_LOG_LIST_FILTERS = 'CLEAR_CHANGES_LOG_LIST_FILTERS';

export const SET_CHANGES_LOG_LIST_SORTING = 'SET_CHANGES_LOG_LIST_SORTING';
export const CLEAR_CHANGES_LOG_LIST_SORTING = 'CLEAR_CHANGES_LOG_LIST_SORTING';
