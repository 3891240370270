import restApi from 'services/api/restApi';

import {User, AuthResponse} from 'entities/User/types';

import {APP_STATE_USER_DATA_FIELD_NAME, APP_STATE_USER_SETTINGS_FIELD_NAME} from '../constants';
import * as types from './actionTypes';

export function login(userData: {user: User} & AuthResponse) {
    return function (dispatch: any): void {
        const loginAction: types.UserLoggedIn = {
            type: types.USER_LOGGED_IN,
            payload: userData,
        };
        dispatch(loginAction);
    };
}

export function logout(): any {
    return function (dispatch: any) {
        const logoutAction: types.UserLoggedOut = {
            type: types.USER_LOGGED_OUT,
        };
        dispatch(logoutAction);
        // we should call this actions for recreate links
        // between local storage and state for userData and userSettings
        dispatch({
            type: 'persist/REHYDRATE',
            key: APP_STATE_USER_DATA_FIELD_NAME,
        });
        dispatch({
            type: 'persist/REHYDRATE',
            key: APP_STATE_USER_SETTINGS_FIELD_NAME,
        });
    };
}
