import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {User} from 'entities/User/types';

import useLogoutActions from '../../hooks/useLogoutActions';

import styles from './loginInfo.module.scss';

interface LoginSettings {
    user: User | undefined;
}

const LoginInfo = ({user}: LoginSettings): JSX.Element | null => {
    const {logout} = useLogoutActions();
    if (user === undefined) {
        return null;
    }
    const dropdown = (
        <div className={styles.wrap}>
            <div className={styles.dropdown}>
                <div className={styles.dropdown__user}>
                    <div className={styles.dropdown__user_name}>{user.email}</div>
                    <div className={styles.dropdown__user_email}>{user.email}</div>
                </div>

                <ul className={styles.dropdown__actions}>
                    <li>
                        <Link to="/view" className={styles.dropdown__actions_item} aria-disabled={true}>
                            View Profile
                        </Link>
                    </li>
                    <li>
                        <span
                            className={classNames(styles.dropdown__actions_item, styles.dropdown__actions_logout)}
                            onClick={logout}
                        >
                            Log Out
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
    return (
        <div className={styles.main}>
            <div className={styles.main__name}>{user.email}</div>
            <div className={styles.main__permission} />
            {dropdown}
        </div>
    );
};

export default LoginInfo;
