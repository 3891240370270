import * as actionTypes from 'app/actions/actionTypes';
import {User} from 'entities/User/types';

type UserAuthActions = actionTypes.UserLoggedIn | actionTypes.UserLoggedOut;
type UserDataState = {
    isUserLoggedIn: boolean;
    user?: User;
    access_token?: string;
    expires_in?: number;
    refresh_token?: string;
    scope?: string;
    token_type?: string;
};

const defaultState: UserDataState = {
    isUserLoggedIn: false,
};

export default function userData(state = defaultState, action: UserAuthActions): UserDataState {
    switch (action.type) {
        case actionTypes.USER_LOGGED_IN: {
            return {
                ...action.payload,
                isUserLoggedIn: true,
            };
        }

        case actionTypes.USER_LOGGED_OUT: {
            return {
                isUserLoggedIn: false,
            };
        }

        default:
            return state;
    }
}
