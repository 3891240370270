import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router';
import {Provider} from 'react-redux';
import {createBrowserHistory} from 'history';
import {PersistGate} from 'redux-persist/lib/integration/react';

import App from 'app';
import {store, persistor} from 'app/store';

import 'antd/dist/antd.css';
import 'app/scss/custom.scss';
import 'react-image-lightbox/style.css';

const history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <Router history={history}>
                <App />
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
