import ChangeRegionModal from './ChangeRegionModal';
import CreateNewCompanyModal from './CreateNewCompanyModal';
import SuccessfulCreateCompanyModal from './SuccessfulCreateCompanyModal';

export const companiesModalNames = {
    changeCompanyRegion: 'CHANGE_COMPANY_REGION_MODAL',
    createNewCompany: 'CREATE_NEW_COMPANY',
    successfulCreateCompanyModal: 'SUCCESSFUL_CREATE_COMPANY_MODAL',
};

const modalMap = {
    [companiesModalNames.changeCompanyRegion]: ChangeRegionModal,
    [companiesModalNames.createNewCompany]: CreateNewCompanyModal,
    [companiesModalNames.successfulCreateCompanyModal]: SuccessfulCreateCompanyModal,
};

export default modalMap;
