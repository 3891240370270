import React from 'react';

import ModalProvider from 'components/Modals/ModalProvider';
import Router from 'router';

const Main = (): JSX.Element => {
    return (
        <>
            <Router />
            <ModalProvider />
        </>
    );
};

export default Main;
