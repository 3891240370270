import map from 'lodash/map';
import head from 'lodash/head';
import filter from 'lodash/filter';

import {NetworkBenefits} from 'entities/NetworkBenefits/types';

import * as FormTypes from 'pages/NetworkBenefits/types';

import {removeFilePath} from 'utils/files';

import {NetworksState} from '../reducers/networks';

const transformBenefitsDataToRequestBody = (params: {formValues: Partial<FormTypes.NetworkBenefitsFormValues>}) => {
    const {formValues} = params;

    return map(formValues.benefits, (value) => ({
        ...value,
        logo: head(value.logo)?.uploadedData || removeFilePath(head(value.logo)?.url),
    }));
};

const transformCompaniesDataToRequestBody = (params: {formValues: Partial<FormTypes.NetworkBenefitsFormValues>}) => {
    const {formValues} = params;

    return map(formValues.companies, (value) => value.value);
};

export const transformNetworkBenefitsDataToRequestBody = (params: {
    formValues: Partial<FormTypes.NetworkBenefitsFormValues>;
}) => {
    const {formValues} = params;

    const formData = new FormData();

    const companies = transformCompaniesDataToRequestBody({formValues});
    const benefits = transformBenefitsDataToRequestBody({formValues});

    formData.append('name', formValues.networkName || '');

    companies.forEach((company, index) => {
        formData.append(`carriers[${index}]`, `${company}`);
    });

    benefits.forEach((benefit, index) => {
        formData.append(`benefits[${index}][title]`, benefit.title || '');
        formData.append(`benefits[${index}][link]`, benefit.link || '');
        formData.append(`benefits[${index}][description]`, benefit.description || '');
        formData.append(`benefits[${index}][logo]`, benefit.logo || '');
    });

    return formData;
};

export const handleReducerByDeleteNetwork = (params: {state: NetworksState; id: string}) => {
    const {state, id} = params;

    const items = filter(state.items, (value) => value.id !== id);

    return {
        ...state,
        items,
    };
};

export const handleReducerByUpdateNetwork = (params: {state: NetworksState; id: string; network: NetworkBenefits}) => {
    const {state, id, network} = params;

    const items = map(state.items, (value) => (value.id === id ? network : value));

    return {
        ...state,
        items,
    };
};
