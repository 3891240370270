import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import LoaderSpinner from 'components/LoaderSpinner';

import {userIsAuthenticatedRedir, userIsNotAuthenticatedRedir} from './auth';

const NetworkBenefits = React.lazy(() => import('../pages/NetworkBenefits'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));
const ChangesLog = React.lazy(() => import('../pages/ChangesLog'));
const LoginComponent = React.lazy(() => import('../pages/Login'));
const Companies = React.lazy(() => import('../pages/Companies'));
const MobileApp = React.lazy(() => import('../pages/MobileApp'));
const Users = React.lazy(() => import('../pages/Users'));

const NetworkBenefitsPage = userIsAuthenticatedRedir(NetworkBenefits);
const LoginPage = userIsNotAuthenticatedRedir(LoginComponent);
const ChangesLogPage = userIsAuthenticatedRedir(ChangesLog);
const CompaniesPage = userIsAuthenticatedRedir(Companies);
const MobileAppPage = userIsAuthenticatedRedir(MobileApp);
const UsersPage = userIsAuthenticatedRedir(Users);

export default (): JSX.Element => {
    return (
        <Suspense fallback={<LoaderSpinner />}>
            <Switch>
                <Route exact path="/" component={CompaniesPage} />
                <Route exact path="/companies/:action?/:id?" component={CompaniesPage} />
                <Route exact path="/users" component={UsersPage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/mobile" component={MobileAppPage} />
                <Route exact path="/network-benefits/:action?" component={NetworkBenefitsPage} />
                <Route exact path="/changes-log" component={ChangesLogPage} />
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};
