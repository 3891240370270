export const FETCH_CURRENT_COMPANY_REQUEST = 'FETCH_CURRENT_COMPANY_REQUEST';
export const FETCH_CURRENT_COMPANY_SUCCESS = 'FETCH_CURRENT_COMPANY_SUCCESS';
export const FETCH_CURRENT_COMPANY_FAILURE = 'FETCH_CURRENT_COMPANY_FAILURE';

export const SET_ACTIVE_CURRENT_CARD = 'SET_ACTIVE_CURRENT_CARD';

export const CLEAR_CURRENT_COMPANY_STATE = 'CLEAR_CURRENT_COMPANY_STATE';

export const FETCH_UPDATE_MAP_SETTINGS_REQUEST = 'FETCH_UPDATE_MAP_SETTINGS_REQUEST';
export const UPDATE_MAP_SETTINGS_SUCCESS = 'UPDATE_MAP_SETTINGS_SUCCESS';

export const FETCH_UPDATE_LOAD_START_NUMBER_REQUEST = 'FETCH_UPDATE_LOAD_START_NUMBER_REQUEST';
export const UPDATE_LOAD_START_NUMBER_SUCCESS = 'UPDATE_LOAD_START_NUMBER_SUCCESS';

export const FETCH_UPDATE_EMAIL_SENDERS_SETTINGS_REQUEST = 'FETCH_UPDATE_EMAIL_SENDERS_SETTINGS_REQUEST';
export const UPDATE_EMAIL_SENDERS_SETTINGS_SUCCESS = 'UPDATE_EMAIL_SENDERS_SETTINGS_SUCCESS';

export const FETCH_UPDATE_GENERAL_INFO_REQUEST = 'FETCH_UPDATE_GENERAL_INFO_REQUEST';
export const UPDATE_GENERAL_INFO_SUCCESS = 'UPDATE_GENERAL_INFO_SUCCESS';

export const FETCH_UPDATE_GLOBAL_SETTINGS_REQUEST = 'FETCH_UPDATE_GLOBAL_SETTINGS_REQUEST';
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';

export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';

export const FETCH_CREATE_COMPANY_REQUEST = 'FETCH_CREATE_COMPANY_REQUEST';
export const FETCH_CREATE_COMPANY_SUCCESS = 'FETCH_CREATE_COMPANY_SUCCESS';
export const FETCH_CREATE_COMPANY_FAILURE = 'FETCH_CREATE_COMPANY_FAILURE';

export const SET_COMPANY_REGION = 'SET_COMPANY_REGION';
export const SET_COMPANY_ACTIVATION_STATUS = 'SET_COMPANY_ACTIVATION_STATUS';

export const FETCH_UPDATE_VISUAL_SETTINGS_REQUEST = 'FETCH_UPDATE_VISUAL_SETTINGS_REQUEST';
export const UPDATE_VISUAL_SETTINGS_SUCCESS = 'UPDATE_VISUAL_SETTINGS_SUCCESS';

export const UPDATE_COMPANY_NETWORKS_REQUEST = 'UPDATE_COMPANY_NETWORKS_REQUEST';
export const UPDATE_COMPANY_NETWORKS_SUCCESS = 'UPDATE_COMPANY_NETWORKS_SUCCESS';
