export const FETCH_MOBILE_SETTINGS_REQUEST = 'FETCH_MOBILE_SETTINGS_REQUEST';
export const FETCH_MOBILE_SETTINGS_SUCCESS = 'FETCH_MOBILE_SETTINGS_SUCCESS';
export const FETCH_MOBILE_SETTINGS_FAILURE = 'FETCH_MOBILE_SETTINGS_FAILURE';

export const SET_ACTIVE_CURRENT_CARD = 'SET_ACTIVE_CURRENT_CARD';

export const CLEAR_MOBILE_SETTINGS_STATE = 'CLEAR_MOBILE_SETTINGS_STATE';

export const FETCH_UPDATE_MAP_SETTINGS_REQUEST = 'FETCH_UPDATE_MAP_SETTINGS_REQUEST';
export const UPDATE_MAP_SETTINGS_SUCCESS = 'UPDATE_MAP_SETTINGS_SUCCESS';
