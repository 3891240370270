import {InferActions} from 'app/types';

import {MobileApp} from 'entities/Mobile/types';

import * as constants from '../constants';
import {actionCreators} from '../actions';
import * as actionsTypes from '../actionTypes';

export type ActiveCurrentCard = typeof constants.MOBILE_MAP_CARD | null;

export type MobileState = {
    data: Partial<MobileApp>;
    isLoading: boolean;
    isError: boolean;
    activeCurrentCard: ActiveCurrentCard;
    updatingMapSettings: boolean;
    succeed: boolean;
};

export const defaultState: MobileState = {
    data: {},
    isLoading: false,
    isError: false,
    activeCurrentCard: null,
    updatingMapSettings: false,
    succeed: false,
};

export type ActionsTypes = InferActions<typeof actionCreators>;

export default function mobileReducer(state: MobileState = defaultState, action: ActionsTypes): MobileState {
    switch (action.type) {
        case actionsTypes.FETCH_MOBILE_SETTINGS_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }

        case actionsTypes.FETCH_MOBILE_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    maps_settings: action.payload,
                },
                isLoading: false,
                activeCurrentCard: null,
                updatingMapSettings: false,
            };
        }

        case actionsTypes.FETCH_MOBILE_SETTINGS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isError: true,
                updatingMapSettings: false,
                succeed: false,
            };
        }

        case actionsTypes.SET_ACTIVE_CURRENT_CARD: {
            return {
                ...state,
                activeCurrentCard: action.payload,
                isError: false,
                succeed: false,
            };
        }

        case actionsTypes.UPDATE_MAP_SETTINGS_SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    maps_settings: action.payload,
                },
                isLoading: false,
                isError: false,
                activeCurrentCard: null,
                updatingMapSettings: false,
                succeed: true,
            };
        }

        case actionsTypes.FETCH_UPDATE_MAP_SETTINGS_REQUEST: {
            return {
                ...state,
                updatingMapSettings: true,
            };
        }

        case actionsTypes.CLEAR_MOBILE_SETTINGS_STATE: {
            return defaultState;
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
