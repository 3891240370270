import {useLayoutEffect, useState} from 'react';
import {clusterName} from 'clusterSettings/routing';

export default (pathToModule: string) => {
    const [styles, setStyles] = useState<{[key: string]: any} | undefined>(undefined);

    const localClusterName = clusterName;
    useLayoutEffect(() => {
        import(`../../${pathToModule}-${localClusterName}.module.scss`).then((module) => setStyles(module));
    }, []);

    return styles;
};
