import React, {useState} from 'react';
import {Modal} from 'antd';
import {useHistory} from 'react-router-dom';

import {Region} from 'entities/Companies/types';

import RegionSelect from 'pages/Companies/components/common/modals/RegionSelect';

interface OwnProps {
    data: {};
    handlers: {
        chooseRegion: (region: Region) => void;
    };
    onClose(): void;
}

const CreateNewCompanyModal: React.FC<OwnProps> = ({onClose, handlers}) => {
    const [region, setRegion] = useState('');
    const history = useHistory();

    const onOk = () => {
        handlers.chooseRegion(region as Region);
        history.push('companies/create');
    };

    return (
        <Modal
            title="Choose Company Continent"
            visible={true}
            onOk={onOk}
            onCancel={onClose}
            okText="Choose"
            okButtonProps={{disabled: !region}}
        >
            <p>
                <strong>Continent where the company operates</strong>
            </p>
            <RegionSelect value={region} onSelect={setRegion} />
        </Modal>
    );
};

export default CreateNewCompanyModal;
