import React from 'react';
import {Modal} from 'antd';
import {CheckCircleFilled} from '@ant-design/icons';
import {useHistory} from 'react-router-dom';

import styles from './styles.module.scss';

interface OwnProps {
    data: {
        company_name: string;
        id: string;
    };
    onClose: () => void;
}

const SuccessfulCreateCompanyModal = (props: OwnProps): JSX.Element => {
    const {
        data: {company_name, id},
        onClose,
    } = props;
    const history = useHistory();

    const onOk = () => {
        history.push(`/companies/view/${id}`);
        onClose();
    };

    const onCancel = () => {
        history.push('/companies');
        onClose();
    };

    return (
        <Modal
            title="Successful Create"
            visible={true}
            onOk={onOk}
            okText="View Card"
            onCancel={onCancel}
            cancelText="View List"
        >
            <div className={styles.wrap}>
                <div className={styles.icon}>
                    <CheckCircleFilled />
                </div>
                <div className={styles.name}>{company_name}</div>
                <div className={styles.text}>company&apos;s card has been created successfully.</div>
            </div>
        </Modal>
    );
};

export default SuccessfulCreateCompanyModal;
