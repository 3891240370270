import {InferActions, OptionSelect} from 'app/types';

import {NetworkBenefits} from 'entities/NetworkBenefits/types';

import * as actionTypes from '../actionTypes';
import {actionCreators} from '../actions';
import * as mappers from '../mappers';

export type NetworksState = {
    networkIndexToUpdate: number | null;
    selectOptions: OptionSelect[];
    items: NetworkBenefits[];
};

const defaultState: NetworksState = {
    networkIndexToUpdate: null,
    selectOptions: [],
    items: [],
};

export type ActionsTypes = InferActions<typeof actionCreators>;

const networksReducer = (state = defaultState, action: ActionsTypes): NetworksState => {
    switch (action.type) {
        case actionTypes.NETWORK_BENEFITS_LIST_RECEIVED: {
            return {
                ...state,
                items: action.payload.items,
            };
        }

        case actionTypes.NETWORK_INDEX_TO_UPDATE_CLEARED: {
            return {
                ...state,
                networkIndexToUpdate: null,
            };
        }

        case actionTypes.NETWORK_INDEX_TO_UPDATE_RECEIVED: {
            return {
                ...state,
                networkIndexToUpdate: action.payload.index,
            };
        }

        case actionTypes.NETWORK_DELETED: {
            return mappers.handleReducerByDeleteNetwork({state, id: action.payload.id});
        }

        case actionTypes.NETWORK_UPDATED: {
            const {id, network} = action.payload;

            return mappers.handleReducerByUpdateNetwork({state, id, network});
        }

        case actionTypes.NETWORKS_SELECT_OPTIONS_RECEIVED: {
            return {
                ...state,
                selectOptions: action.payload.options,
            };
        }

        // no default
    }

    const _exhaustiveCheck: never = action;

    return state;
};

export default networksReducer;
