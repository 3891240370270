import React from 'react';
import {useSelector} from 'react-redux';

import {getCurrentUser} from 'app/reducers/userData/selectors';
import LoginInfo from './components/LoginInfo';

import styles from './header.module.scss';

const Header = (): JSX.Element => {
    const currentUser = useSelector(getCurrentUser);

    return (
        <div className={styles.wrap}>
            <div />
            <LoginInfo user={currentUser} />
        </div>
    );
};

export default Header;
