import {InferActions} from 'app/types';
import {User} from 'entities/User/types';

import {FETCH_LIST_USERS_RECEIVED, FETCH_LIST_USERS_FAILURE} from '../actionTypes';
import {actionsList} from '../actions/list';

export interface UsersState {
    users: User[];
    isError: boolean;
}

export const defaultState: UsersState = {
    users: [],
    isError: false as boolean,
};

type ActionsTypes = InferActions<typeof actionsList>;

export default function usersReducer(state = defaultState, action: ActionsTypes): UsersState {
    switch (action.type) {
        case FETCH_LIST_USERS_RECEIVED: {
            const {users} = action.payload;
            return {
                ...state,
                users,
            };
        }

        case FETCH_LIST_USERS_FAILURE: {
            return {
                ...state,
                isError: true,
            };
        }

        // no default
    }
    // eslint-disable-next-line
    const _exhaustiveCheck: never = action;

    return state;
}
