import * as types from 'app/actions/actionTypes';
import {AppData} from 'app/types';

const defaultState: AppData = {
    auth: {
        isLoggedIn: false,
    },
    modalData: undefined,
    showLoaderSpinner: false,
};

export default (state = defaultState, action: types.AppActionTypes): AppData => {
    switch (action.type) {
        case types.SHOW_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: true,
            };
        }

        case types.HIDE_LOADER_SPINNER: {
            return {
                ...state,
                showLoaderSpinner: false,
            };
        }

        case types.SHOW_MODAL: {
            return {
                ...state,
                modalData: action.payload,
            };
        }

        case types.HIDE_MODAL: {
            return {
                ...state,
                modalData: undefined,
            };
        }

        default:
            return state;
    }
};
