import {AuthResponse, User} from 'entities/User/types';

export const SHOW_LOADER_SPINNER = 'SHOW_LOADER_SPINNER';
export const HIDE_LOADER_SPINNER = 'HIDE_LOADER_SPINNER';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export interface ShowModal {
    type: typeof SHOW_MODAL;
    payload: {modalName: string; modalData: any};
}

export interface HideModal {
    type: typeof HIDE_MODAL;
}

export interface ShowLoaderSpinner {
    type: typeof SHOW_LOADER_SPINNER;
}

export interface HideLoaderSpinner {
    type: typeof HIDE_LOADER_SPINNER;
}

export interface UserLoggedIn {
    type: typeof USER_LOGGED_IN;
    payload: {user: User} & AuthResponse;
}

export interface UserLoggedOut {
    type: typeof USER_LOGGED_OUT;
}

export type AppActionTypes = ShowModal | HideModal | ShowLoaderSpinner | HideLoaderSpinner;
