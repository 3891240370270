interface AppConfig {
    API_URL: string;
    BUILD_HASH: any;
    ENVIRONMENT_TYPE: any;
    IS_DEV_MODE: boolean;
    isCaravanTheme: boolean;
}

const appConfig: AppConfig = {
    API_URL: '/api',
    IS_DEV_MODE: process.env.NODE_ENV === 'development',
    ENVIRONMENT_TYPE: window?.__BUILD_CONFIG__?.REACT_APP_ENV || process.env.REACT_APP_ENV,
    BUILD_HASH: process.env.NODE_ENV === 'development' ? '123456789' : (process.env.REACT_APP_BUILD_ID as string),
    isCaravanTheme:
        window?.__BUILD_CONFIG__?.REACT_APP_CLUSTER === 'caravan' ||
        process.env.REACT_APP_CLUSTER === 'caravan' ||
        localStorage.getItem('enable_caravan') === '1',
};

export default appConfig;
