import React from 'react';
import Layout from 'antd/lib/layout';

import Header from '../layout/Header';
import LeftSidebar from '../layout/LeftSidebar';
import Main from '../layout/Main';
import useIsLoggedInUser from './hooks/useIsLoggedInUser';

const {Content} = Layout;

const App = (): JSX.Element => {
    const isUserLoggedIn = useIsLoggedInUser();
    return (
        <Layout>
            {isUserLoggedIn && <LeftSidebar />}
            <Layout>
                {isUserLoggedIn && <Header />}
                <Content>
                    <Main />
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;
