import {useDispatch} from 'react-redux';
import {ThunkAction} from 'redux-thunk';
import {Action} from 'redux';

import {AppState} from 'app/types';

import {logout} from 'app/actions/authActions';

export default function () {
    const dispatch = useDispatch();
    return {
        logout: (): Promise<ThunkAction<void, AppState, unknown, Action<string>>> => {
            return dispatch(logout());
        },
    };
}
