import {AppState} from 'app/types';
import {User} from 'entities/User/types';

export const getCurrentUser = (state: AppState): User | undefined => state.userData.user;

export const isUserLoggedIn = (state: AppState): boolean => {
    return state?.userData?.isUserLoggedIn;
};

export const getUserAccessToken = (state: AppState) => state?.userData?.access_token;
