import React from 'react';
import Spin from 'antd/lib/spin';

import styles from './loader.module.scss';

const LoaderSpinner = (): JSX.Element | null => {
    return (
        <div className={styles.spinner}>
            <Spin size="large" />
        </div>
    );
};

export default LoaderSpinner;
