import split from 'lodash/split';
import last from 'lodash/last';

import {File} from 'pages/Companies/types';

export const getFilePath = (value: string): string => `/files/${value}` || '';

export const removeFilePath = (value?: string): string => value?.replace(/\/files\//, '') || '';

export const getFileName = (value: string): string => last(split(value, '/')) || '';

export const transformFileData = (value: string, uid: string): File => {
    const fileName = getFileName(value);
    const filesUrl = getFilePath(value);

    return {
        uid,
        name: fileName,
        status: 'done',
        url: filesUrl,
    };
};
