import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import config from 'config';

import {APP_STATE_USER_DATA_FIELD_NAME, APP_STATE_USER_SETTINGS_FIELD_NAME} from 'app/constants';
import * as actionTypes from 'app/actions/actionTypes';
import userData from 'app/reducers/userData';
import appData from 'app/reducers/appData';

import networkBenefits from 'pages/NetworkBenefits/redux/reducers';
import companies from 'pages/Companies/redux/reducers';
import changesLog from 'pages/ChangesLog/reducers';
import mobile from 'pages/MobileApp/reducers';
import users from 'pages/Users/reducers';

const appReducer = combineReducers({appData, userData, companies, mobile, networkBenefits, users, changesLog});

const rootReducer = (state: any, action: any): ReturnType<typeof appReducer> =>
    appReducer(action.type !== actionTypes.USER_LOGGED_OUT ? state : undefined, action);

const newAppBuildId: string = config.BUILD_HASH;

const persistedReducer = persistReducer(
    {
        key: `admin-panel-persists-store-${newAppBuildId}`,
        storage,
        whitelist: [APP_STATE_USER_DATA_FIELD_NAME, APP_STATE_USER_SETTINGS_FIELD_NAME],
    },
    rootReducer,
);

export default persistedReducer;
